import * as Backbone from 'backbone';
import * as _ from 'underscore';
import {CSS_CLASSES} from 'front/constants/Constants';

export default class Router extends Backbone.Router {
    routes() {
        let routes = {
            '': 'index',
            'articles(/)': 'postList',
            'articles(/:slug/)': 'post',
            'about(/)': 'about',
            'privacy(/)': 'privacy',
            'contacts(/)': 'contacts',
            'illustrations(/)': 'illustrations',
            'illustrations/illustration(/:id/)': 'illustration',
            'illustrations/authors(/)': 'illustrationsAuthors',
            'illustrations/clients(/)': 'illustrationsClients',
            'illustrations/style(/)': 'illustrationsStyle',
            'animations(/)': 'animations',
            'animations/authors(/)': 'animationsAuthors',
            'animations/clients(/)': 'animationsClients',
            'animations/style(/)': 'animationsStyle',
            'storyboards(/)': 'storyboards',
            'storyboards/authors(/)': 'storyboardsAuthors',
            'storyboards/clients(/)': 'storyboardsClients',
            'storyboards/style(/)': 'storyboardsStyle',
            'order(/)': 'order',
            'illustrations/search(/)': 'illustrationsSearch',
            'animations/search(/)': 'animationsSearch',
            'storyboards/search(/)': 'storyboardsSearch',
            'articles-search/search(/)': 'postListSearch',
            'illustrations(/:slug/)': 'illustrationsAuthor',
            'animations(/:slug/)': 'animationsAuthor',
            'storyboards(/:slug/)': 'storyboardsAuthor',
            '404(/)': 'notFound'
        };

        if (window.app.settings.language !== window.app.settings.defaultLanguage) {
            let langRoutes = {};

            for (let route in routes) {
                langRoutes[window.app.settings.language + '/' + route] = routes[route];
            }

            routes = langRoutes;
        }

        return routes;
    };

    index() {
        this.importComponent('Index');
    }

    postList() {
        this.importComponent('PostList');
    }

    post() {
        this.importComponent('Post');
    }

    about() {
        this.importComponent('About');
    }

    privacy() {
        this.importComponent('PrivacyPolicy');
    }

    contacts() {
        this.importComponent('Contacts');
    }

    illustrations() {
        this.importComponent('Illustrations');
    }

    illustration() {
        this.importComponent('Illustration');
    }

    illustrationsAuthors() {
        this.importComponent('IllustrationsAuthors');
    }

    illustrationsClients() {
        this.importComponent('IllustrationsClients');
    }

    illustrationsStyle() {
        this.importComponent('IllustrationsStyle');
    }

    illustrationsAuthor() {
        this.importComponent('IllustrationsAuthor');
    }

    animations() {
        this.importComponent('Animations');
    }

    animationsAuthors() {
        this.importComponent('AnimationsAuthors');
    }

    animationsClients() {
        this.importComponent('AnimationsClients');
    }

    animationsStyle() {
        this.importComponent('AnimationsStyle');
    }

    animationsAuthor() {
        this.importComponent('AnimationsAuthor');
    }

    storyboards() {
        this.importComponent('Storyboards');
    }

    storyboardsAuthors() {
        this.importComponent('StoryboardsAuthors');
    }

    storyboardsClients() {
        this.importComponent('StoryboardsClients');
    }

    storyboardsStyle() {
        this.importComponent('StoryboardsStyle');
    }

    storyboardsAuthor() {
        this.importComponent('StoryboardsAuthor');
    }

    notFound() {
        this.importComponent('NotFound');
    }

    order() {
        this.importComponent('Order');
    }

    illustrationsSearch() {
        this.importComponent('IllustrationsSearch');
    }

    animationsSearch() {
        this.importComponent('AnimationsSearch');
    }

    storyboardsSearch() {
        this.importComponent('StoryboardsSearch');
    }

    postListSearch() {
        this.importComponent('PostListSearch');
    }

    importComponent(pageName) {
        import('front/pages/' + pageName + '/' + pageName).then(({default: Component}) => {
            this.activate(Component);
        });
    }

    activate(view, params = {}) {
        // view is rendered on server
        if (!app.state.view) {
            params.isServerRendered = true; // if view was rendered on server side
            params.inAnimated = false;
            app.state.viewConstructor = view;
            app.state.view = new view(params);
            app.state.view.activate();
            app.state.currentViewClass = view;

            return;
        }

        app.state.prevView = app.state.view;

        this.activateLogic(view, params);
    }

    activateLogic(view, params) {
        const newParams = {...params, isServerRendered: false, isAnimated: true}; // params for view rendered on client side
        app.state.viewConstructor = view;

        const newView = new view(newParams);

        return Promise.all([app.state.view.playOut({
            view: newView
        }), newView.loadData()])
            .then(() => {
                app.els.$contentBody.css({minHeight: app.els.$contentBody.height()});

                return app.state.view.deactivate({destroy: true});
            })
            .then(() => {
                app.state.isServerRendered = false;
                app.state.view = newView;
                app.state.view.activate(newParams)
                    .then(function () {
                        app.els.$contentBody.css({minHeight: ''});
                        app.els.$window.scrollTop(0);

                        return app.state.view.playIn({
                            view: newView
                        });
                    });
            });
    }

    start() {
        const is404 = app.els.$body.hasClass(CSS_CLASSES.notFound);
        const pushStateSupported = history && _.isFunction(history.pushState);
        Backbone.history.start({pushState: pushStateSupported, silent: is404, hashChange: false});

        if (is404) {
            this.notFound();
        }
    }
}
