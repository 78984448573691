import * as Backbone from 'backbone';
import * as _ from 'underscore';

// Uncomment if need IE 11 support
// import svg4everybody from 'svg4everybody';
// import objectFitImages from 'object-fit-images/dist/ofi.common-js.js';
// import Promise from 'promise-polyfill';

import Settings from './Settings';
import Utils from 'front/utils/Utils';
import Router from './Router';
import MobileDetect from 'mobile-detect';

// Here we declare root components that are rendered on server
// and exist all the time
import TopNav from 'front/components/Common/TopNav/TopNav';
import MobileTopNav from 'front/components/Common/MobileTopNav/MobileTopNav';
import Footer from 'front/components/Common/Footer/Footer';

// Import styles
import 'reset.css';
import 'front/fonts.less';
import 'front/utils.less';
import 'front/style.less';
import {BACKBONE_EVENTS, CSS_CLASSES, CSS_PROPERTIES, SELECTORS, VIEWPORT_EVENTS} from './constants/Constants';

// Polyfills initialization, uncomment if need IE 11 support
// CSS object-fit for IE & old browsers
// objectFitImages();

// SVG sprites for IE
// svg4everybody();

// Promise for IE
// if (!window.Promise) {
//     window.Promise = Promise;
// }

const mobileDetect = new MobileDetect(window.navigator.userAgent);
const phone = mobileDetect.phone();
const tablet = mobileDetect.tablet();

window.app.settings.isDesktop = !phone && !tablet;
window.app.settings.isMobile = phone || tablet;
window.app.settings.isTablet = !!tablet;
window.app.settings.isPhone = !!phone;

document.documentElement.className += ' ' + (app.settings.isDesktop ? 'isDesktop' : 'isNotDesktop');
document.documentElement.className += ' ' + (!app.settings.isDesktop ? 'isMobile' : 'isNotMobile');
document.documentElement.className += ' ' + (app.settings.isTablet ? 'isTablet' : 'isNotTablet');
document.documentElement.className += ' ' + (app.settings.isPhone ? 'isPhone' : 'isNotPhone');

app.configure = Settings.configure;
app.configure();

app.vent = _.extend({}, Backbone.Events);

app.state = {};
app.cache = {};
app.utils = Utils;

app.state = {};

// Global storage for frequently used DOM elements
// Example usage in views: app.els.$body
app.els = {
    $window: $(window),
    $body: $(SELECTORS.body),
    $html: $(SELECTORS.html),
    $htmlBody: $(SELECTORS.htmlBody),
    $content: $(SELECTORS.content),
    $contentBody: $(SELECTORS.contentBody),
    $topNav: $(SELECTORS.topNav),
    $mobileTopNav: $(SELECTORS.mobileTopNav),
    $footer: $(SELECTORS.footer)
};

// Common views, which can be used on each pages
app.views = {
    topNav: new TopNav(),
    mobileTopNav: new MobileTopNav(),
    footer: new Footer()
};

// Enabling frontend SPA routing
app.els.$body.on(VIEWPORT_EVENTS.click, SELECTORS.uRoute, (event) => {
    if (app.els.$body.hasClass(CSS_CLASSES.notFound)) {
        return;
    }

    const url = $(event.currentTarget).attr('href') || $(event.target).closest('a')
        .attr('href');

    event.preventDefault();
    Backbone.history.navigate(url, {trigger: 'true'});
});

app.router = new Router();

app.router.start();

app.els.$window
    .on(VIEWPORT_EVENTS.load, () => {
        app.els.$content.css(CSS_PROPERTIES.visibility, '');

        window.app.vent.trigger(BACKBONE_EVENTS.windowLoaded);
    });
