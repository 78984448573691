import * as Backbone from 'backbone';

import {VIEWPORT_EVENTS} from '../../../constants/Constants';
import './MobileTopNav.less';

const Selectors = {
    el: '.MobileTopNav',
    item: '.MobileTopNav-a',
    languageItem: '.MobileTopNav-languageItem'
};

export default class MobileTopNav extends Backbone.View {
    events() {
        return {
            [`${VIEWPORT_EVENTS.click} ${Selectors.languageItem}`]: this.onLanguage
        };
    }

    constructor() {
        super({el: Selectors.el});

        this.location = location?.pathname;

        if (this.location.substr(1, 2).indexOf('en') !== -1) {
            this.$(Selectors.languageItem).eq(1)
                .addClass('active');
        } else {
            this.$(Selectors.languageItem).eq(0)
                .addClass('active');
        }

        this.$(Selectors.item).map((i, el) => {
            if (this.location.indexOf($(el).data('id')) !== -1) {
                $(el).addClass('active');
            }
        });
    }

    onLanguage(e) {
        e.preventDefault();
        if (window.location.pathname.split('/')[1] === 'en') {
            window.location.pathname = window.location.pathname.split('/')[2];
        } else {
            if (window.location.pathname === '/') {
                window.location.pathname = '/en/' + window.location.pathname.split('/')[1];
            } else {
                window.location.pathname = '/en/' + window.location.pathname.split('/')[1] + '/';
            }
        }
    }
};
