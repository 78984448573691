import * as Backbone from 'backbone';
import * as _ from 'underscore';

import {VIEWPORT_EVENTS, SELECTORS} from '../../../constants/Constants';
import './TopNav.less';

const Selectors = {
    el: '.TopNav',
    item: '.TopNav-a',
    burger: '.TopNav-mobileMenuButton',
    languageItem: '.TopNav-languageItem'
};

const BindFunctions = [
    'onMobileMenu'
];

export default class TopNav extends Backbone.View {
    events() {
        return {
            [`${VIEWPORT_EVENTS.click} ${Selectors.burger}`]: this.onMobileMenu,
            [`${VIEWPORT_EVENTS.click} ${Selectors.languageItem}`]: this.onLanguage
        };
    }

    constructor() {
        super({el: Selectors.el});
        _.bindAll(this, BindFunctions);

        if (location?.pathname.substr(1, 2).indexOf('en') !== -1) {
            this.$(Selectors.languageItem).eq(1)
                .addClass('active');
        } else {
            this.$(Selectors.languageItem).eq(0)
                .addClass('active');
        }

        this.$(Selectors.item).map((i, el) => {
            if (location?.pathname.indexOf($(el).data('id')) !== -1) {
                $(el).addClass('active');
            }
        });
    }

    onLanguage(e) {
        e.preventDefault();
        if (window.location.pathname.split('/')[1] === 'en') {
            window.location.pathname = window.location.pathname.split('/')[2];
        } else {
            if (window.location.pathname === '/') {
                window.location.pathname = '/en/' + window.location.pathname.split('/')[1];
            } else {
                window.location.pathname = '/en/' + window.location.pathname.split('/')[1] + '/';
            }
        }
    }

    onMobileMenu() {
        const $mobileMenu = $(SELECTORS.mobileTopNav);

        if ($mobileMenu.hasClass('show')) {
            $mobileMenu.removeClass('show');
            app.els.$htmlBody.removeClass('disableScroll');

            app.els.$htmlBody.animate({
                scrollTop: this.currentScroll
            }, 0);

            $mobileMenu.find('a').each((i, el) => {
                let $el = $(el);

                $el.stop(true, false);
                $el.fadeTo(0, 0);
            });
        } else {
            this.currentScroll = $(window).scrollTop();
            $mobileMenu.addClass('show');

            $mobileMenu.find('a').each((i, el) => {
                const $el = $(el);

                if ($el.hasClass('active')) {
                    $el.delay((i++) * 100).fadeTo(200, 1);
                } else {
                    $el.delay((i++) * 100).fadeTo(200, .5);
                }
            });

            setTimeout(() => {
                app.els.$htmlBody.addClass('disableScroll');
            }, 300);
        }
    }
};
